import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Logos from "../components/logos";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark } = data; // data.markdownRemark holds your post data
    const { siteMetadata } = site;
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>

            <div className="w-full mx-auto">
                <div className="py-10 sm:pt-16 lg:pt-8 lg:pb-8 lg:overflow-hidden bg-header">
                    <div className="mx-auto max-w-7xl px-4 lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                            <div className=" sm:text-center px-4 lg:px-8 lg:text-left lg:flex lg:items-center">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="text-base block font-semibold tracking-wide uppercase">Policy Work</span>
                                        <span className="pb-3 block text-white sm:pb-5">What we provide</span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="bg-white my-10 sm:my-0">
                <div class="relative mx-auto max-w-7xl px-4 lg:px-8">
                    <div class="relative md:bg-white md:p-6">
                        <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                            <div class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                                <p>Our services have been developed to ensure that our clients receive technically accurate and authoritative support. We offer consultancy services in all aspects of electrical installations</p>

                            </div>
                            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">

                                <p>We also offer a partner package to support an organisation over a longer timeframe. Our services are not limited to the above. Please contact us and we will be happy to discuss your requirements.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="relative bg-white pb-16 xl:pb-36">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                    <div className="">
                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            <div className="pt-6 h-full">
                                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                                    <div className="-mt-6">
                                        <div>
                                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                                </svg>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">RESPOND TO CONSULTATION</h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            As part of our responsibility to engage with the wider industry, Tim Benstead Associates Ltd respond to a wide range of drafts for public comment for British Standards and proposed legislation and regulation.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-6 h-full">
                                <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8 h-full">
                                    <div className="-mt-6">
                                        <div>
                                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md">
                                                <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                                </svg>
                                            </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">SUPPORT BRITISH STANDARDS</h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            Tim continues to support the work of British Standards with his work on JPEL64 (Electrical installations of buildings – Joint committee and as Chair of JPEL64/B – Wiring regulations thermal effects) and FSH12/1 Fire detection and alarm systems (Installation and servicing). Tim also supports the work of FSH/017/0-/03 Drafting of BS 8629 (Evacuation alert systems).
                                        </p>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>

            <Logos />
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        metaDescription
        types {
            heading
            description
        }
      }
    }
  }
`;
